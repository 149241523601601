<template>
  <div>
    <slot />
    <Notifications />
  </div>
</template>

<script setup></script>

<style scoped></style>
